<template>
  <v-container fill-height fluid grid-list-xl pb-5 mb-5>
    <v-layout column white wrap ma-2>
      <!-- CONNECTED USER'S CUSTOMER'S PROJECTS -->
      <v-flex px-4>
        <h2>
          Projets
        </h2>
      </v-flex>
      <v-flex>
        <v-flex right py-0>
          <v-btn v-if="isAdmin" center class="success" @click="addProject">
            <v-icon>mdi-plus-circle-outline</v-icon>
            <v-flex ml-2 pa-0>
              Ajouter un sprint
            </v-flex>
          </v-btn>
        </v-flex>
      </v-flex>
      <material-DataTableProject
        :items="projects"
        :loading="loading"
        can-search
        @click:row="projectDetails"
        @click:edit="editProject"
        @click:delete="deleteProject"
      />
      <v-flex>
        <v-card>
          <v-card-title>
            <h5>
              <v-icon color="info">
                mdi-help-circle-outline
              </v-icon>
              Aide
            </h5>
          </v-card-title>
          <v-flex ml-2>
            Vos Projets sont découpés en Sprints.
          </v-flex>
          <v-flex ml-2>
            <ul>
              <li>
                <v-icon color="success">
                  <!-- progress 2 -->
                  mdi-check-circle
                </v-icon>
                Tous les Sprints sont recettés.
              </li>
              <li>
                <v-icon color="warning">
                  <!-- progress 1 -->
                  mdi-alert-circle
                </v-icon>
                Au moins un Sprint n'est pas recetté.
              </li>
              <li>
                <v-icon color="error">
                  <!-- progress 0 -->
                  mdi-close-circle
                </v-icon>
                Aucun Sprint n'est recetté.
              </li>
            </ul>
          </v-flex>
        </v-card>
      </v-flex>

      <ui-DialogBox
        title="Suppression d'un projet"
        msg="Voulez-vous vraiment supprimer le projet ?"
        :show="projectToDelete > 0"
        icon-title="mdi-delete-outline"
        cancel-icon="close-circle-outline"
        ok-icon="check-circle-outline"
        @cancel="projectToDelete = null"
        @ok="confirmDeleteProject(projectToDelete)"
      />

      <!-- SNACKBAR -->
      <ui-SnackBarV2
        :text="text"
        :color="color"
        :bottom="bottom"
        :top="top"
        :right="right"
        :left="left"
        :snackbar="snackbar"
      />
    </v-layout>
  </v-container>
</template>
<script>
import RoleMixin from '@/mixins/RoleMixin';
import SnackBarMixin from '@/mixins/SnackBarMixin';

export default {
  name: 'ProjectDetails',

  mixins: [RoleMixin, SnackBarMixin],

  data: () => ({
    customerId: null,
    loading: false,
    projectToDelete: null,
  }),

  computed: {
    projects() {
      return this.$store.getters['project/getCustomerProjects']() || null;
    },
  },

  created() {
    this.customerId = this.$store.getters['user/getScopeUser']().customers_id;
  },

  mounted() {
    // get the project and the sprints associate
    this.$store.commit('app/showLoader');
    this.$store
      .dispatch('project/get_customer_projects', this.customerId)
      .then((response) => {
        console.log('Projects OK', response);
      })
      .catch((error) => {
        console.log('Erreur', error);
        this.snack({
          position: 'bottom',
          align: 'center',
          color: 'error',
          text: 'Impossible de récupérer les projets : ' + error.message,
        });
      })
      .finally(() => {
        this.$store.commit('app/showLoader');
        this.$store.commit('app/resetDashboardIds');
      });
  },

  methods: {
    projectDetails(id) {
      console.log('projectDetails', id);
      this.$router.push({ path: `/project/${id}` });
    },

    addProject() {
      console.log('addProject');
      this.$router.push({ path: `/project/create` });
    },

    editProject(id) {
      console.log('editProject', id);
      this.$router.push({ path: `/project/${id}/edit` });
    },

    deleteProject(id) {
      console.log('deleteProject', id);
      this.projectToDelete = id;
    },
    confirmDeleteProject(id) {
      console.log('confirmDeleteProject', id);
      this.$store
        .dispatch('project/delete_project_by_id', id)
        .then(() => {
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'success',
            text: 'Projet supprimé avec succès',
          });
        })
        .catch((error) => {
          this.snack({
            position: 'bottom',
            align: 'center',
            color: 'error',
            text: 'Impossible de supprimer le projet : ' + error.message,
          });
        })
        .finally(() => {
          this.projectToDelete = null;
        });
    },
  },
};
</script>
